<template>
  <div>
    <b-button
      id="idMessagerNotificationBtn"
      class="btn-icon rounded-circle"
      variant="flat-primary"
      size="md"
    >
      <svg-icon
        v-if="msgNotViewed"
        type="mdi"
        :path="mdiBellBadgeOutline"
        size="24"
      />
      <svg-icon
        v-else
        type="mdi"
        :path="mdiBellOutline"
        size="24"
      />
    </b-button>
    <b-popover
      id="idMessagerPopoverNotification"
      target="idMessagerNotificationBtn"
      triggers="hover"
      placement="bottomleft"
      @click="openNotificationCard()"
      @hidden="closeNotificationCard()"
    >
      <template #title>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <span class="clsTitle">Notificações</span>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-end"
            cols="12"
            md="6"
          >
            <span class="clsSubTitle">Mostrar Apenas Não Lidas</span>
            <b-form-checkbox
              id="idSwitchOnlyViewed"
              v-model="onlyNotViewed"
              class="ml-50"
              name="check-button"
              switch
            />
          </b-col>
        </b-row>
      </template>
      <div
        v-if="(isLoading && (messagesByPage.length == 0))"
        id="idNotificationLoading"
        class="d-flex align-items-center justify-content-center"
      >
        <b-spinner
          class="my-2 clsWaitLoad"
          label="Busy"
        />
      </div>
      <div
        v-else
      >
        <div
          v-if="!msgNotViewed"
        >
          <b-row class="p-1">
            <b-col>
              <span>Você não possuí nenhuma nova notificação.</span>
            </b-col>
          </b-row>
        </div>
        <div
          v-else
        >
          <vue-perfect-scrollbar
            id="idNotificationScroll"
            :settings="settingsScrollbar"
          >
            <div
              v-for="(messages, key) in messagesByPage"
              :key="key"
            >
              <div
                v-for="message in messages"
                :key="message.id"
              >
                <div
                  v-if="message.author !== profile.id"
                >
                  <b-row
                    class="py-1 m-0 clsMessageCard"
                    @click="open(message)"
                  >
                    <b-col
                      class="p-0"
                    >
                      <div
                        class="mx-1 d-flex align-items-center"
                      >
                        <b-avatar size="3rem" />
                        <div class="d-inline ml-1 w-100">
                          <b-row>
                            <b-col
                              cols="12"
                              md="8"
                            >
                              <b-row>
                                <b-col>
                                  <span class="clsAuthor">{{ message.author_set.first_name }}</span>
                                  <span class=""> marcou você em </span>
                                  <span
                                    v-if="message.module === 'bidding'"
                                    class="clsAuthor"
                                  >
                                    uma licitação:
                                  </span>

                                  <span
                                    v-if="message.module === 'order'"
                                    class="clsAuthor"
                                  >
                                    um pedido:
                                  </span>

                                  <span
                                    v-if="message.module === 'contract'"
                                    class="clsAuthor"
                                  >
                                    um contrato:
                                  </span>

                                  <span
                                    v-if="message.module === 'commitment'"
                                    class="clsAuthor"
                                  >
                                    um empenho:
                                  </span>

                                  <span
                                    v-if="message.module === 'delivery'"
                                    class="clsAuthor"
                                  >
                                    uma entrega:
                                  </span>

                                  <span
                                    v-if="message.module !== 'order' && message.module !== 'bidding' && message.module !== 'contract' && message.module !== 'commitment' && message.module !== 'delivery'"
                                    class="clsAuthor"
                                  >
                                    uma menssagem:
                                  </span>

                                </b-col>
                              </b-row>
                              <b-row class="">
                                <b-col>
                                  <span>"{{ message.message | truncate(35, '...') }}"</span>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col
                              class="clsDate d-flex align-items-center justify-content-end p-0"
                              cols="12"
                              md="4"
                            >
                              <span>{{ getDate(message.created_at) }}</span>
                              <svg-icon
                                class="mr-50"
                                :class="getIsViewed(message) ? 'clsMessageViewed' : 'clsMessageNotViewed'"
                                type="mdi"
                                :path="mdiCircleMedium"
                                size="24"
                              />
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <hr
                    class="m-0"
                  >
                </div>
              </div>
            </div>
            <observer @on-change="loadMore">
              <div
                v-if="(isLoading && (messagesByPage.length > 0))"
                class="d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  class="my-2 clsWaitLoad"
                  label="Busy"
                />
              </div>
            </observer>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BPopover,
  BAvatar,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'

import {
  mdiBellOutline,
  mdiCircleMedium,
  mdiBellBadgeOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import moment from 'moment'
import Observer from 'vue-intersection-observer'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import syslic from '@/syslic'
import truncate from '@/components/utils/filters/truncate'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BPopover,
    BAvatar,
    BFormCheckbox,
    BSpinner,
    SvgIcon,
    VuePerfectScrollbar,
    Observer,
  },
  filters: {
    truncate,
  },
  data() {
    return {
      fetchId: '',
      maxPage: '',
      messagesByPage: [],
      profile: syslic.authentication.getProfile(),
      onlyNotViewed: true,
      isLoading: true,
      msgNotViewed: false,
      page: 1,
      perPage: 15,
      settingsScrollbar: {
        maxScrollbarLength: 60,
      },
    }
  },
  watch: {
    onlyNotViewed: function updateView() {
      this.messagesByPage = []
      this.resetLoad()
      this.load(false)
    },
  },
  created() {
    this.resetLoad()
    this.load(false)
    this.taskRefreshMessages()
  },
  methods: {
    resetLoad() {
      this.maxPage = ''
      this.page = 1

      this.messagesByPage.forEach((obj, index) => {
        if (index > 1) {
          this.messagesByPage.splice(index, 1)
        }
      })
    },
    getDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
    getIsViewed(message) {
      let isViewed = false

      message.views_by_profile.forEach(obj => {
        if (obj.id === this.profile.id) {
          isViewed = true
        }
      })

      return isViewed
    },
    closeNotificationCard() {
      this.onlyNotViewed = true
    },
    openNotificationCard() {
      this.load(false)
    },
    loadMore(entry) {
      if (entry.isIntersecting) {
        this.load(true)
      }
    },
    load(addPage) {
      const fetchPending = Math.random()
      this.fetchId = fetchPending

      let { page } = this

      if (addPage) {
        if (page < this.maxPage) {
          page += 1
        } else {
          return
        }
      }

      const params = {
        p: page,
        page_size: this.perPage,
        ordering: '-created_at',
        mentions__id: this.profile.id,
        not_author: this.profile.id,
      }

      if (this.onlyNotViewed) {
        params.not_viewed_by = this.profile.id
      }

      this.isLoading = true

      syslic
        .messager
        .fetchMessages(params)
        .then(response => {
          if (fetchPending === this.fetchId) {
            if (this.maxPage === '') {
              this.maxPage = Math.ceil(Number(response.data.count / this.perPage))
            }
            if (addPage) {
              this.page = page
            }

            this.messagesByPage[page] = response.data.results
            this.refreshMsgNotViewed()
            this.isLoading = false
          }
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.isLoading = false
          }
        })
    },
    taskRefreshMessages() {
      if (this.page !== 1) {
        return
      }

      this.load(false)

      setTimeout(() => {
        this.taskRefreshMessages()
      }, 60000)
    },
    refreshMsgNotViewed() {
      let result = false
      this.messagesByPage.forEach(page => {
        page.forEach(() => {
          result = true
        })
      })

      this.msgNotViewed = result
    },
    open(message) {
      if (message.module === 'bidding') {
        router.push({
          name: 'bidding-view',
          params: {
            id: message.thread,
          },
        })
      } else if (message.module === 'order') {
        router.push({
          name: 'order-detail',
          params: {
            id: message.thread,
          },
        })
      } else if (message.module === 'delivery') {
        router.push({
          name: 'delivery-detail',
          params: {
            idDelivery: message.thread,
          },
        })
      } else if (message.module === 'price-records') {
        router.push({
          name: 'records-view',
          params: {
            id: message.thread,
          },
        })
      } else if (message.module === 'contract') {
        router.push({
          name: 'contract-detail',
          params: {
            id: message.thread,
          },
        })
      } else if (message.module === 'commitment') {
        router.push({
          name: 'order-commitment-detail',
          params: {
            id: message.thread,
          },
        })
      } else {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Não foi possível abrir esta menssagem.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            timeout: false,
          },
        })
      }
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiBellOutline,
      mdiCircleMedium,
      mdiBellBadgeOutline,
    }
  },
}
</script>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idMessagerPopoverNotification {
  min-width: 40rem !important;
}

#idMessagerPopoverNotification .popover-header {
  color: $primary !important;
  background-color: #F6F6F9 !important;
  border: 1px solid #ebe9f1;
}

#idMessagerPopoverNotification .popover-body {
  padding: 0px;
}

#idMessagerPopoverNotification .clsWaitLoad {
  width: 2rem;
  height: 2rem;
  color: $primary;
}
#idMessagerPopoverNotification .clsTitle {
  font-weight: 600;
  font-size: 1.25rem;
}

#idMessagerPopoverNotification .clsSubTitle {
  font-weight: 400;
  font-size: 0.9rem;
  color: #6e6b7b;
}

#idMessagerPopoverNotification .clsAuthor {
  font-weight: 600;
  color: $primary;
}

#idMessagerPopoverNotification .clsDate {
  text-align: right;
}

.clsMessageViewed {
  color: #6e6b7b;
}

.clsMessageNotViewed {
  color: $primary;
}

.clsMessageCard:hover {
  background-color: #F6F6F9;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer
}

#idSwitchOnlyViewed {
  max-width: 1rem;
}

#idNotificationScroll {
  position: relative;
  margin: 0px;
  height: 30rem;
}

#idNotificationLoading {
  height: 30rem;
}
</style>
