<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-1"
      cols="12"
      md="12"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h3 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h3>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <svg-icon
                  v-if="$route.meta.iconSvg"
                  class="svgIconBreadcrumb"
                  type="mdi"
                  :path="$route.meta.iconSvg"
                />
                <feather-icon
                  v-else
                  :icon="$route.meta.icon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="getUrl(item)"
              >
                {{ getValue(item) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SvgIcon from '@jamescoyle/vue-icon'
import router from '@/router'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    SvgIcon,
  },
  methods: {
    getUrl(item) {
      let url = ''

      if ('to' in item) {
        url = router.resolve({
          path: item.to,
          params: router.currentRoute.params,
        }).href
      } else if ('toName' in item) {
        url = { name: item.toName }
      }
      return url
    },
    getValue(item) {
      let value = ''

      if ('prefix' in item) {
        value = item.prefix
      }

      if ('text' in item) {
        value = value.concat(item.text)
      } else if ('routerParam' in item) {
        value = value.concat(router.currentRoute.params[item.routerParam])
      }

      return value
    },
  },
}
</script>

<style lang="scss">

.svgIconBreadcrumb {
  width: 18px;
  height: 18px;
}
</style>
