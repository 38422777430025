<template>
  <div>
    <span id="version-number">Versão {{ version }}</span>
  </div>
</template>

<script>
import syslic from '@/syslic'

export default {
  components: {
  },
  data() {
    return {
      version: syslic.version,
    }
  },
}
</script>
<style lang="scss">
#version-number {
  color: #46464664;
  font-weight: 500;
}
</style>
