<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <b-navbar-brand
      id="navbar-logo"
      class="ml-auto d-xl-none"
    >
      <img src="@/assets/images/logo/logo_light_2.png">
    </b-navbar-brand>

    <b-navbar-nav
      id="navbarHeader"
      class="nav align-items-center d-xl-flex d-none"
    >
      <b-nav-item class="contextAccount mr-auto">
        <context-account-modal
          ref="refContextAccount"
          :user-id="userData.id"
          :context-default="userData.account"
          :readonly="false"
        />
      </b-nav-item>
      <b-nav-item>
        <messager-notification />
      </b-nav-item>

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user d-xl-flex d-none"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.first_name }}
            </p>
            <small class="mb-0">
              {{ profile }}
            </small>
          </div>
          <b-avatar
            id="navbar-avatar"
            size="40"
            variant="secondary"
            badge
            :src="imageSource"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'profile-detail' }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Meu Perfil</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          v-if="userData.is_admin"
          link-class="d-flex align-items-center"
          @click="openContextAccount()"
        >
          <svg-icon
            class="mr-50"
            type="mdi"
            size="22"
            :path="mdiAccountSwitch"
          />
          <span>Minhas Contas</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logoff"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavbarBrand,
  BNavItem,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'

import {
  mdiAccountSwitch,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import syslic from '@/syslic'
import appLogoImage from '@/assets/images/logo/logo.png'
import MessagerNotification from '@/components/messager/MessagerNotification.vue'
import ContextAccountModal from '@/components/administrator/ContextAccountModal.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavbarBrand,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    MessagerNotification,
    ContextAccountModal,
    SvgIcon,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    const userData = syslic.authentication.getProfile()
    const imageSource = syslic.authentication.getProfilePhoto()
    const profile = this.getProfile(userData)

    syslic.authentication.bindNotificationProfileUpdate(() => {
      this.userData = syslic.authentication.getProfile()
      this.profile = this.getProfile(this.userData)
      this.imageSource = syslic.authentication.getProfilePhoto()
    })

    return {
      userData,
      imageSource,
      profile,
    }
  },
  methods: {
    logoff() {
      syslic.authentication.logoff()
      this.$router.push({ name: 'login' })
    },
    pushToProfileView() {
      this.$router.push({
        name: 'user-view',
        params: { id: this.userData.get_uuid },
      })
    },
    getProfile(user) {
      let profile = ''
      if (user.permission_set) {
        profile = user.permission_set.name
      }
      return profile
    },
    openContextAccount() {
      this.$refs.refContextAccount.openModalContextAccount()
    },
  },
  setup() {
    return {
      appLogoImage,
      mdiAccountSwitch,
    }
  },
}
</script>
<style lang="scss">
#navbar-logo > img {
  max-height: 40px;
}

.contextAccount {
  margin-right: 1rem;
}

#navbarHeader {
  width: 100%;
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: 16rem;
}

</style>
