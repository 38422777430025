<template>
  <div>
    <div
      class="contextAccountHeader"
    >
      <span>
        {{ contextAccountName }}
      </span>
    </div>
    <b-modal
      id="idModalChangeContextAccount"
      ref="modalChangeContextAccount"
      no-close-on-backdrop
      no-close-on-esc
      centered
      cancel-variant="outline-primary"
      hide-footer
      size="lg"
    >
      <template #modal-title>
        Alternar entre Contas
      </template>
      <b-form
        class="mt-1"
      >
        <b-row>
          <b-col>
            <account-select
              id="accountSelect"
              v-model="accountContext"
              :readonly="false"
              :required-field="false"
              label="Selecione uma Conta"
              placeholder="Selecione uma Conta"
            />
          </b-col>
        </b-row>
        <b-row class="mb-2 mt-5">
          <b-col>
            <div class="d-flex justify-content-center">
              <b-button
                v-if="profile.account !== profile.context_account"
                id="changeContAccountBtn"
                class="selectorBtn"
                variant="success"
                @click="backToMyAccount()"
              >
                <svg-icon
                  class="mr-50"
                  type="mdi"
                  size="22"
                  :path="mdiArrowLeft"
                />
                <span>
                  Minha conta
                </span>
              </b-button>
              <b-button
                class="selectorBtn"
                variant="primary"
                :disabled="accountContext === null"
                @click="changeContextAccount()"
              >
                <svg-icon
                  class="mr-50"
                  type="mdi"
                  size="22"
                  :path="mdiSwapHorizontal"
                />
                <span>
                  Alternar
                </span>
              </b-button>

              <b-button
                class="selectorBtn"
                variant="outline-primary"
                @click="cancel()"
              >
                <span>
                  Cancelar
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="idModalUpdateContext"
      ref="modalUpdateContext"
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      centered
    >
      <div class="pt-5 pb-4">
        <div class="d-flex justify-content-center">
          <b-spinner
            id="spinnerChangeContext"
            variant="primary"
            type="grow"
          />
        </div>
        <h4
          class="d-flex justify-content-center mt-2 pl-50"
        >
          Mudando de Conta...
        </h4>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton,
  BForm,
  BModal,
  BRow,
  BCol,
  BSpinner,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiArrowLeft,
  mdiSwapHorizontal,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'
import AccountSelect from '@/components/administrator/AccountSelect.vue'
import router from '@/router'

export default {
  components: {
    BButton,
    BForm,
    BModal,
    BRow,
    BCol,
    BSpinner,
    AccountSelect,
    SvgIcon,
  },
  props: {
    userId: {
      type: [Number, String],
      required: true,
    },
    contextDefault: {
      type: [Number, String],
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    const contextAccountName = '-'

    const accountContext = null

    const isUpdate = false

    const profile = syslic.authentication.getProfile()

    return {
      contextAccountName,
      accountContext,
      isUpdate,
      profile,
    }
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      syslic
        .administrator
        .account
        .fetchContextAccount(this.userId)
        .then(response => {
          if (response.data.context_account_set.is_master) {
            this.contextAccountName = ''
          } else {
            this.contextAccountName = response.data.context_account_set.name
          }
        })
    },
    openModalContextAccount() {
      this.$refs.modalChangeContextAccount.show()
    },
    backToMyAccount() {
      this.$refs.modalChangeContextAccount.hide()
      this.openUpdate()

      const params = {
        select_account: this.contextDefault,
      }

      syslic
        .administrator
        .account
        .updateContextAccount(this.userId, params)
        .then(() => {
          const promise = syslic.authentication.loadProfile()
          return promise
        })
        .then(() => {
          if (router.currentRoute.name !== 'bidding') {
            return router.push({ name: 'bidding' })
          }
          return undefined
        })
        .then(() => {
          this.cancel()
          window.location.reload()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao trocar o contexto da conta.',
              text: 'Não foi possível trocar o contexto da conta, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.cancel()
        })
    },
    changeContextAccount() {
      this.$refs.modalChangeContextAccount.hide()
      this.openUpdate()

      const params = {
        select_account: this.accountContext.id,
      }

      syslic
        .administrator
        .account
        .updateContextAccount(this.userId, params)
        .then(() => {
          const promise = syslic.authentication.loadProfile()
          return promise
        })
        .then(() => {
          if (router.currentRoute.name !== 'bidding') {
            return router.push({ name: 'bidding' })
          }
          return undefined
        })
        .then(() => {
          this.cancel()
          window.location.reload()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao trocar o contexto da conta.',
              text: 'Não foi possível trocar o contexto da conta, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.cancel()
        })
    },
    cancel() {
      this.closeUpdate()
      this.accountContext = null
      this.$refs.modalChangeContextAccount.hide()
    },
    openUpdate() {
      this.$refs.modalUpdateContext.show()
    },
    closeUpdate() {
      this.$refs.modalUpdateContext.hide()
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiArrowLeft,
      mdiSwapHorizontal,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.contextAccountHeader {
  color: #FFF;
  opacity: 1 !important;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#idModalChangeContextAccount .vc-slider {
  width: 100% !important;
}

#changeContAccountBtn {
  background-color: $success_2;
}

#spinnerChangeContext {
  width: 5rem;
  height: 5rem;
}

.selectorBtn {
  width: 12rem;
  margin-left: 1rem;
}

</style>
