<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <div
              id="navbar-icon-box"
              class="navbar-brand"
            >
              <div
                class="ml-auto d-xl-flex d-none"
              >
                <span class="brand-logo">
                  <b-img
                    :src="appIconImage"
                    alt="logo"
                  />
                </span>

                <div>
                  <b-img
                    id="appLogoTitleImage"
                    :src="appLogoTitleImage"
                    alt="logo"
                  />
                </div>
              </div>
              <b-button
                id="btn-profile"
                class="d-xl-none d-flex align-items-center pl-50 pt-1"
                variant="outline-primary"
                block
                :to="{ name: 'profile-detail' }"
              >
                <b-avatar
                  id="navbar-avatar"
                  size="40"
                  variant="secondary"
                  badge
                  :src="imageSource"
                  class="badge-minimal"
                  badge-variant="success"
                />
                <span class="pl-1">
                  <p class="d-flex font-weight-bolder mb-0">
                    {{ userData.first_name }}
                  </p>
                  <small class="mb-0">
                    {{ profile }}
                  </small>
                </span>
              </b-button>
            </div>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area d-flex flex-column"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
      <div
        v-if="userData.account != userData.context_account"
        class="mt-auto mb-2 d-flex justify-content-center"
      >
        <b-spinner
          small
          variant="success"
          type="grow"
        />
        <div
          v-if="isMouseHovered || !isVerticalMenuCollapsed"
        >
          <span class="ml-1">Modo Visitante</span>
        </div>
      </div>
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BLink,
  BImg,
  BAvatar,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import navMenuItems from '@/navigation/vertical'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import appIconImage from '@/assets/images/logo/icon_dark.png'
import appLogoTitleImage from '@/assets/images/logo/logo_title_2.png'
import syslic from '@/syslic'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BAvatar,
    BButton,
    BSpinner,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const userData = syslic.authentication.getProfile()
    const imageSource = syslic.authentication.getProfilePhoto()
    const profile = ''
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appIconImage,
      appLogoTitleImage,
      userData,
      imageSource,
      profile,
    }
  },

  created() {
    this.profile = this.getProfile(this.userData)
  },

  methods: {
    getProfile(user) {
      let profile = ''
      if (user.permission_set) {
        profile = user.permission_set.name
      }
      return profile
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

#logo-text {
  color: #282425;
}

#appLogoTitleImage {
  max-height: 37px;
  margin-bottom: 10px;
  margin-left: 21px;
  margin-top: 12px;
}

#navbar-icon-box {
  margin-top: 0px;
}

#navbar-icon-box .brand-logo{
  margin-top: 15px;
}

#btn-profile {
  border: 0 !important;
}

@media (max-width: 1199px) {
  .main-menu ul.navigation-main {
    margin-top: 15px;
  }
}
</style>
