import {
  mdiCash,
  mdiGavel,
  mdiFileDocumentEditOutline,
  mdiClipboardEditOutline,
  mdiMonitorDashboard,
  mdiClipboardTextMultipleOutline,
  mdiChartBar,
} from '@mdi/js'

const configMenu = [{
  title: 'Configurações',
  route: 'setup',
  icon: 'SettingsIcon',
  children: [
    {
      title: 'Acessos',
      route: 'access',
      icon: 'CircleIcon',
      resource: ['User', 'Permission'],
      action: 'read',
      children: [
        {
          title: 'Permissões',
          route: 'permission',
          icon: 'CircleIcon',
          resource: 'Permission',
          action: 'read',
        },
        {
          title: 'Usuários',
          route: 'user',
          icon: 'CircleIcon',
          resource: 'User',
          action: 'read',
        },
      ],
    },
    {
      title: 'Licitações',
      icon: 'CircleIcon',
      resource: ['BiddingSettings'],
      action: 'read',
      children: [
        {
          title: 'Disputa',
          route: 'bidding-dispute',
          icon: 'CircleIcon',
          resource: 'BiddingSettings',
          action: 'read',
        },
        {
          title: 'Fases',
          route: 'bidding-phase',
          icon: 'CircleIcon',
          resource: 'BiddingSettings',
          action: 'read',
        },
        {
          title: 'Interesse',
          route: 'bidding-interest',
          icon: 'CircleIcon',
          resource: 'BiddingSettings',
          action: 'read',
        },
        {
          title: 'Modalidade',
          route: 'bidding-modality',
          icon: 'CircleIcon',
          resource: 'BiddingSettings',
          action: 'read',
        },
        {
          title: 'Plataforma',
          route: 'bidding-platform',
          icon: 'CircleIcon',
          resource: 'BiddingSettings',
          action: 'read',
        },
        {
          title: 'Exigências',
          route: 'bidding-requirements',
          icon: 'CircleIcon',
          resource: 'BiddingSettings',
          action: 'read',
        },
        {
          title: 'Status',
          route: 'bidding-status',
          icon: 'CircleIcon',
          resource: 'BiddingSettings',
          action: 'read',
        },
        {
          title: 'Tipo',
          route: 'bidding-type',
          icon: 'CircleIcon',
          resource: 'BiddingSettings',
          action: 'read',
        },
      ],
    },
    {
      title: 'Pedidos',
      icon: 'CircleIcon',
      resource: ['OrderSettings'],
      action: 'read',
      children: [
        {
          title: 'Auditoria',
          route: 'order-audit',
          icon: 'CircleIcon',
          resource: 'OrderSettings',
          action: 'read',
        },
        {
          title: 'Prioridade',
          route: 'order-interest',
          icon: 'CircleIcon',
          resource: 'OrderSettings',
          action: 'read',
        },
        {
          title: 'Status do Faturamento',
          route: 'order-status-invoicing',
          icon: 'CircleIcon',
          resource: 'OrderSettings',
          action: 'read',
        },
        {
          title: 'Status do Empenho',
          route: 'order-status-commitment',
          icon: 'CircleIcon',
          resource: 'OrderSettings',
          action: 'read',
        },
        {
          title: 'Status da Entrega',
          route: 'order-status-delivery',
          icon: 'CircleIcon',
          resource: 'OrderSettings',
          action: 'read',
        },
        {
          title: 'Status da Assistência',
          route: 'order-status-assistance',
          icon: 'CircleIcon',
          resource: 'OrderSettings',
          action: 'read',
        },
      ],
    },
    {
      title: 'Contrato',
      icon: 'CircleIcon',
      resource: ['ContractSettings'],
      action: 'read',
      children: [
        {
          title: 'Status do Contrato',
          route: 'contract-status',
          icon: 'CircleIcon',
          resource: 'ContractSettings',
          action: 'read',
        },
      ],
    },
    {
      title: 'Fornecedores',
      icon: 'CircleIcon',
      resource: ['SupplierSettings'],
      action: 'read',
      children: [
        {
          title: 'Categoria',
          route: 'supplier-category',
          icon: 'CircleIcon',
          resource: 'SupplierSettings',
          action: 'read',
        },
      ],
    },
    {
      title: 'Produtos',
      icon: 'CircleIcon',
      resource: ['ProductSettings'],
      action: 'read',
      children: [
        {
          title: 'Categorias',
          route: 'product-classifier',
          icon: 'CircleIcon',
          resource: 'ProductSettings',
          action: 'read',
        },
        {
          title: 'Garantias',
          route: 'product-warranty',
          icon: 'CircleIcon',
          resource: 'ProductSettings',
          action: 'read',
        },
        {
          title: 'Tipos',
          route: 'product-type',
          icon: 'CircleIcon',
          resource: 'ProductSettings',
          action: 'read',
        },
        {
          title: 'Unidades',
          route: 'product-unity',
          icon: 'CircleIcon',
          resource: 'ProductSettings',
          action: 'read',
        },
      ],
    },
    {
      title: 'Transporte',
      icon: 'CircleIcon',
      resource: ['TransportSettings'],
      action: 'read',
      children: [
        {
          title: 'Estimativa de Frete',
          route: 'transport-freight',
          icon: 'CircleIcon',
          resource: 'TransportSettings',
          action: 'read',
        },
      ],
    },
  ],
}]

let logoutMenu = [
  {
    title: 'Logout',
    route: 'logout',
    icon: 'LogOutIcon',
    resource: 'Auth',
    action: 'read',
  },
]

const appMenu = [
  {
    title: 'Licitações',
    route: 'bidding',
    iconSvg: mdiGavel,
    resource: ['Auth', 'Bidding'],
    action: 'read',
    children: [
      {
        title: 'Buscar Licitações',
        route: 'bidding-search',
        icon: 'CircleIcon',
        resource: ['Auth', 'Bidding'],
        action: 'read',
      },
      {
        title: 'Minhas Licitações',
        route: 'bidding',
        icon: 'CircleIcon',
        resource: ['Auth', 'Bidding'],
        action: 'read',
      },
      {
        title: 'Registros de Preço',
        route: 'records',
        icon: 'CircleIcon',
        resource: 'Bidding',
        action: 'read',
      },
    ],
  },
  {
    title: 'Contratos',
    route: 'contract',
    iconSvg: mdiFileDocumentEditOutline,
    resource: 'Contract',
    action: 'read',
  },
  {
    title: 'Pedidos',
    route: 'order',
    iconSvg: mdiClipboardTextMultipleOutline,
    resource: ['Order'],
    action: 'read',
    children: [
      {
        title: 'Todos os Pedidos',
        route: 'order',
        icon: 'CircleIcon',
        resource: ['Order'],
        action: 'read',
      },
      {
        title: 'Todos os Faturamentos',
        route: 'invoicing',
        icon: 'CircleIcon',
        resource: 'Order',
        action: 'read',
      },
      {
        title: 'Todos os Empenhos',
        route: 'commitment',
        icon: 'CircleIcon',
        resource: 'Order',
        action: 'read',
      },
      {
        title: 'Todas as Entregas',
        route: 'delivery',
        icon: 'CircleIcon',
        resource: 'Order',
        action: 'read',
      },
      {
        title: 'Todas as Assistências',
        route: 'assistance',
        icon: 'CircleIcon',
        resource: 'Order',
        action: 'read',
      },
    ],
  },
  {
    title: 'Cadastros',
    iconSvg: mdiClipboardEditOutline,
    children: [
      {
        title: 'Empresas',
        route: 'company',
        icon: 'CircleIcon',
        resource: 'Company',
        action: 'read',
      },
      {
        title: 'Fornecedores',
        route: 'supplier',
        icon: 'CircleIcon',
        resource: 'Supplier',
        action: 'read',
      },
      {
        title: 'Órgãos',
        route: 'client',
        icon: 'CircleIcon',
        resource: 'Client',
        action: 'read',
      },
      {
        title: 'Produtos',
        route: 'product',
        icon: 'CircleIcon',
        resource: 'Product',
        action: 'read',
      },
      {
        title: 'Transportadoras',
        route: 'transport',
        icon: 'CircleIcon',
        resource: 'Transport',
        action: 'read',
      },
    ],
  },
  {
    title: 'Comissões',
    route: 'commission',
    iconSvg: mdiCash,
    resource: 'Commission',
    action: 'read',
  },
  {
    title: 'Relatórios',
    route: 'report',
    iconSvg: mdiChartBar,
    resource: 'Report',
    action: 'read',
  },
]

const admSettingsMenu = [
  {
    title: 'Sistema',
    iconSvg: mdiMonitorDashboard,
    children: [
      {
        title: 'Administradores',
        route: 'administrators',
        icon: 'CircleIcon',
        resource: 'Admin',
        action: 'read',
      },
      {
        title: 'Contas',
        route: 'administrator-accounts',
        icon: 'CircleIcon',
        resource: 'Admin',
        action: 'read',
      },
      {
        title: 'Planos',
        route: 'administrator-profiles',
        icon: 'CircleIcon',
        resource: 'Admin',
        action: 'read',
      },
    ],
  },
]

if (window.innerWidth >= 1199) {
  logoutMenu = []
}

export default [
  ...appMenu,
  ...configMenu,
  ...admSettingsMenu,
  ...logoutMenu,
]
